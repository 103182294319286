import React, { useEffect, useState } from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Divider,
  colors,
  LinearProgress,
  TextField,
  MenuItem,
  Typography
} from '@material-ui/core';
import Page from 'src/components/Page';
import StatClasse from './StatClasse';
import moment from 'moment';
import StatNombreMontant from './StatNombreMontant';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  partisant: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 10,
    color: colors.cyan[800]
  }
}));

const listMois = [
  { code: 'JANUARY', value: 'Janvier' },
  { code: 'FEBRUARY', value: 'Février' },
  { code: 'MARCH', value: 'Mars' },
  { code: 'APRIL', value: 'Avril' },
  { code: 'MAY', value: 'Mai' },
  { code: 'JUNE', value: 'Juin' },
  { code: 'JULY', value: 'Juillet' },
  { code: 'AUGUST', value: 'Août' },
  { code: 'SEPTEMBER', value: 'Septembre' },
  { code: 'OCTOBER', value: 'Octobre' },
  { code: 'NOVEMBER', value: 'Novembre' },
  { code: 'DECEMBER', value: 'Décembre' }
];

const Dashboard = ({
  requestDashboardData,
  dashboard,
  fetching,
  fetched,
  updateActualMenu
}) => {
  const classes = useStyles();

  const [mois, setMois] = useState('');
  const [annee, setAnnee] = useState('');
  const [listAnnee, setListAnnee] = useState([]);

  useEffect(() => {
    updateActualMenu('DASHBOARD');
  }, [updateActualMenu]);

  useEffect(() => {
    if (mois === '') {
      setMois(getActualMonth());
    }
    if (annee === '') {
      setAnnee(moment().year());
    }
    if (listAnnee.length === 0) {
      setListAnnee(getListAnnees());
    }
    if (!dashboard && mois && annee) {
      requestDashboardData(mois, annee);
    }
  }, [mois, annee, listAnnee, dashboard, requestDashboardData]);

  const getActualMonth = () => listMois[moment().month()].code;

  const getListAnnees = () => {
    let res = [];
    let thisYear = new Date().getFullYear();
    let borneMin = 5;

    for (let i = 0; i < borneMin; i++) {
      res.push(thisYear - i);
    }
    return res;
  };

  const handleChangeMois = event => {
    setMois(event.target.value);
    requestDashboardData(event.target.value, annee);
  };

  const handleChangeAnnee = event => {
    setAnnee(event.target.value);
    requestDashboardData(mois, event.target.value);
  };

  return (
    <Page className={classes.root} title="Tableau de bord">
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <Typography component="div" style={{ display: 'flex' }}>
              <TextField
                margin="normal"
                label="Mois"
                select
                fullWidth
                variant="outlined"
                value={mois}
                onChange={handleChangeMois}
              >
                <MenuItem value="NULL" />
                {listMois.map(option => (
                  <MenuItem key={option.code} value={option.code}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                margin="normal"
                label="Année"
                select
                fullWidth
                variant="outlined"
                value={annee}
                onChange={handleChangeAnnee}
                style={{ marginLeft: 20 }}
              >
                {listAnnee.map(option => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          {fetching && (
            <Grid item xs={12}>
              <LinearProgress
                classes={{
                  colorPrimary: 'primary'
                }}
              />
            </Grid>
          )}
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={3}>
            <StatNombreMontant
              couleur={colors.orange}
              libelle="Nombre de personnes"
              valeur={dashboard ? dashboard.population : 0}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <StatNombreMontant
              couleur={colors.blueGrey}
              libelle="Nombre de SMS envoyés"
              valeur={dashboard ? dashboard.nombre : 0}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <StatNombreMontant
              couleur={colors.red}
              libelle="Coût total"
              valeur={`${dashboard ? dashboard.montant : 0} FCFA`}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <StatNombreMontant
              couleur={colors.blueGrey}
              libelle="Solde SMS restant"
              valeur={dashboard ? dashboard.solde : 0}
            />
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <StatClasse datas={dashboard ? dashboard.detail : []} />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Dashboard;
