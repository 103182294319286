import React from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardHeader,
  Collapse,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  makeStyles
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

const useStyles = makeStyles(() => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  }
}));

function Row({ row }) {
  const [open, setOpen] = React.useState(false);

  const classes = useStyles();

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{row.etablissement}</TableCell>
        <TableCell align="right">{row.population}</TableCell>
        <TableCell align="right">{row.nombre}</TableCell>
        <TableCell align="right">{row.montant} FCFA</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Détail par type de contact
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell>Type</TableCell>
                    <TableCell align="right">Nombre de contacts</TableCell>
                    <TableCell align="right">SMS envoyés</TableCell>
                    <TableCell align="right">Montant</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.detail.map(detail => (
                    <TableRow key={detail.groupe}>
                      <TableCell />
                      <TableCell>{detail.groupe}</TableCell>
                      <TableCell align="right">{detail.population}</TableCell>
                      <TableCell align="right">{detail.nombre}</TableCell>
                      <TableCell align="right">{detail.montant} FCFA</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const StatClasse = ({ className, datas, ...rest }) => {
  const classes = useStyles();

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader title="Statistiques par classe" />
      <Divider />
      <PerfectScrollbar>
        <Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>Etablissement</TableCell>
                <TableCell align="right">Nombre de personnes</TableCell>
                <TableCell align="right">SMS envoyés</TableCell>
                <TableCell align="right">Montant</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {datas && datas.map(row => <Row key={row.name} row={row} />)}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

StatClasse.propTypes = {
  className: PropTypes.string
};

export default StatClasse;
