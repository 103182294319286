import React, { useEffect } from 'react';
import { LinearProgress, Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import SmsForm from './SmsForm';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const SmsView = ({
  etablissements,
  groups,
  fetching,
  fetchingSms,
  listerEtablissements,
  listGroups,
  envoyerSms,
  updateActualMenu
}) => {
  const classes = useStyles();

  useEffect(() => {
    if (!etablissements) {
      listerEtablissements();
    }
    updateActualMenu('SMS');
  }, [etablissements, listerEtablissements, updateActualMenu]);

  useEffect(() => {
    if (!groups) {
      listGroups();
    }
    updateActualMenu('SMS');
  }, [groups, listGroups, updateActualMenu]);

  return (
    <Page className={classes.root} title="SMS">
      <Container maxWidth={false}>
        {(fetching || fetchingSms) && (
          <LinearProgress
            classes={{
              colorPrimary: 'primary'
            }}
          />
        )}
        <SmsForm
          etablissements={etablissements}
          groups={groups}
          save={envoyerSms}
        />
      </Container>
    </Page>
  );
};

export default SmsView;
