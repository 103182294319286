import { LOAD_ETABLISSEMENT, LOAD_GROUPS, SEND_SMS } from './Sms.constants';
import { fetchApiAction } from 'src/setup/Api/api';

export function listGroups() {
  return function(dispatch) {
    dispatch(
      fetchApiAction('commun:groups', {}, result => ({
        type: LOAD_GROUPS,
        data: result
      }))
    );
  };
}

export function listerEtablissements() {
  return function(dispatch) {
    dispatch(
      fetchApiAction('commun:etablissement', {}, result => ({
        type: LOAD_ETABLISSEMENT,
        data: result
      }))
    );
  };
}

export function envoyerSms(payload) {
  return function(dispatch) {
    dispatch(
      fetchApiAction(
        'sms:post',
        { body: payload },
        result => ({
          type: SEND_SMS,
          data: result
        }),
        null,
        "La demande d'envoi de SMS a bien été prise en compte."
      )
    );
  };
}
