import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  FormGroup,
  FormControl,
  FormControlLabel,
  Switch
} from '@material-ui/core';

const SELECT_ALL = 'ALL';

const SmsForm = ({ etablissements, groups, save }) => {
  const [classes, setClasses] = useState([]);
  const [filieres, setFilieres] = useState([]);

  const sendSms = async values => {
    await save(values);
  };

  return (
    <Formik
      initialValues={{
        classe: SELECT_ALL,
        filiere: SELECT_ALL,
        etablissement: SELECT_ALL,
        groupe: SELECT_ALL,
        message: '',
        parent: false,
        telephones: ''
      }}
      validationSchema={Yup.object().shape({
        message: Yup.string().required('Veuillez saisir votre message'),
        groupe: Yup.string().required(
          'Veuillez choisir au moins un destinataire'
        )
      })}
      onSubmit={values => {
        sendSms({
          ...values,
          groupe: values.groupe === SELECT_ALL ? null : values.groupe,
          etablissement:
            values.etablissement === SELECT_ALL ? null : values.etablissement,
          classe: values.classe === SELECT_ALL ? null : values.classe,
          filiere: values.filiere === SELECT_ALL ? null : values.filiere,
          telephones: values.telephones ? values.telephones.split(';') : []
        });
      }}
    >
      {({ setFieldValue, handleChange, handleSubmit, values, isValid }) => (
        <form onSubmit={handleSubmit}>
          <Card>
            <CardHeader
              subheader="Veuillez saisir le message et choisir un établissement et une cible"
              title="Envoi de SMS"
            />
            <Divider />
            <CardContent>
              <Grid container spacing={6} wrap="wrap">
                <Grid item md={6} xs={12}>
                  {groups && (
                    <TextField
                      fullWidth
                      label="Destinataire"
                      name="groupe"
                      onChange={event => {
                        if (event.target.value !== SELECT_ALL) {
                          setFieldValue('groupe', event.target.value);
                        } else {
                          setFieldValue('groupe', SELECT_ALL);
                        }
                        setFieldValue('classe', SELECT_ALL);
                        setFieldValue('filiere', SELECT_ALL);
                        setFieldValue('etablissement', SELECT_ALL);
                        setFieldValue('telephones', '');
                        setFieldValue('parent', false);
                      }}
                      required
                      value={values.groupe}
                      variant="outlined"
                      select
                      SelectProps={{
                        native: true
                      }}
                    >
                      <option value="ALL">-- Tous --</option>
                      {groups.map(option => (
                        <option key={option.code} value={option.code}>
                          {option.value}
                        </option>
                      ))}
                      <option value="PHONE_NUMBER">Numéros de téléphone</option>
                    </TextField>
                  )}
                </Grid>

                {values.groupe !== 'PHONE_NUMBER' && (
                  <>
                    <Grid item md={6} xs={12}>
                      {etablissements && (
                        <TextField
                          fullWidth
                          label="Etablissement"
                          name="etablissement"
                          onChange={event => {
                            if (event.target.value !== SELECT_ALL) {
                              setFieldValue(
                                'etablissement',
                                event.target.value
                              );
                              let clas = etablissements
                                .filter(dep => dep.code === event.target.value)
                                .map(dep => dep.classes);
                              setClasses(clas[0]);

                              let fil = etablissements
                                .filter(dep => dep.code === event.target.value)
                                .map(dep => dep.filieres);
                              setFilieres(fil[0]);
                            } else {
                              setFieldValue('etablissement', SELECT_ALL);
                              setClasses([]);
                              setFilieres([]);
                            }

                            setFieldValue('classe', SELECT_ALL);
                            setFieldValue('filiere', SELECT_ALL);
                          }}
                          value={values.etablissement}
                          variant="outlined"
                          select
                          SelectProps={{
                            native: true
                          }}
                        >
                          <option value="ALL">-- Tous --</option>
                          {etablissements.map(option => (
                            <option key={option.code} value={option.code}>
                              {option.nom}
                            </option>
                          ))}
                        </TextField>
                      )}
                    </Grid>

                    {values.groupe === 'PUPILS' && filieres?.length > 0 && (
                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          label="Filière"
                          name="filiere"
                          onChange={handleChange}
                          value={values.filiere}
                          variant="outlined"
                          select
                          SelectProps={{
                            native: true
                          }}
                        >
                          <option value="ALL">-- Toutes --</option>
                          {filieres.map(option => (
                            <option key={option.code} value={option.code}>
                              {option.nom}
                            </option>
                          ))}
                        </TextField>
                      </Grid>
                    )}
                    {values.groupe === 'STUDENTS' && (
                      <>
                        {classes?.length > 0 && (
                          <Grid item md={6} xs={12}>
                            <TextField
                              fullWidth
                              label="Classe"
                              name="classe"
                              onChange={handleChange}
                              value={values.classe}
                              variant="outlined"
                              select
                              SelectProps={{
                                native: true
                              }}
                            >
                              <option value="ALL">-- Toutes --</option>
                              {classes.map(option => (
                                <option key={option.code} value={option.code}>
                                  {option.nom}
                                </option>
                              ))}
                            </TextField>
                          </Grid>
                        )}

                        <Grid item md={6} xs={12}>
                          <FormControl component="fieldset">
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={values.parent}
                                    onChange={event => {
                                      setFieldValue(
                                        'parent',
                                        event.target.checked
                                      );
                                    }}
                                    color="secondary"
                                    name="parent"
                                  />
                                }
                                label="Envoi aux parents"
                              />
                            </FormGroup>
                          </FormControl>
                        </Grid>
                      </>
                    )}
                  </>
                )}

                {values.groupe === 'PHONE_NUMBER' && (
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Numéros de téléphone"
                      name="telephones"
                      onChange={handleChange}
                      required
                      value={values.telephones}
                      variant="outlined"
                      multiline
                      minRows={2}
                      helperText="Séparer les numéros par des points-virgules (;). Format : 07XXXXXXXX"
                    />
                  </Grid>
                )}

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="SMS"
                    name="message"
                    onChange={handleChange}
                    required
                    value={values.message}
                    variant="outlined"
                    multiline
                    minRows={4}
                    inputProps={{ maxLength: 500 }}
                    helperText={`Nombre de caractère restant: ${500 -
                      values.message.length}`}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <Box display="flex" justifyContent="flex-end" p={2}>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={!isValid}
              >
                Envoyer
              </Button>
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

SmsForm.propTypes = {
  className: PropTypes.string,
  etablissements: PropTypes.array,
  save: PropTypes.func
};

export default SmsForm;
